<template>
  <VContainer
    fluid
    class="question-analytics"
  >
    <VRow class="align-center">
      <VCol cols="9">
        <h1 class="mb-2 tt-text-headline-1">
          {{ title }}
        </h1>
        <p class="tt-black--text text--lighten-2 mb-0">
          {{ path }}
        </p>
        <template v-if="contentType === 'SELECT_MANY'">
          <div>
            <p class="pt-2 pb-0 mb-0 tt-text-body-2">
              Правильные ответы
              <SHelpTooltip max-width="530">
                Ответ считается правильным, когда выбраны все правильные варианты ответов.
                Если выбраны не все варианты или выбран один или несколько неправильных вариантов,
                ответ будет считаться неправильным.
              </SHelpTooltip>
            </p>
            <template v-for="(answer, i) in meta.answers">
              <div
                v-if="answer.is_right"
                :key="i"
                class="pt-0 pb-0"
              >
                <p class="mb-0 tt-text-body-2">
                  {{ answer.label }}
                </p>
              </div>
            </template>
          </div>
        </template>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VRow>
          <VCol
            cols="6"
          >
            <TTTextField
              v-model="filter.name"
              large
              label="Поиск сотрудника"
              placeholder="Поиск по ФИО"
              clearable
              append-icon="fal fa-search"
              data-test="staff-input-search"
              @input="debouncedGetData"
            />
          </VCol>
          <VCol
            cols="6"
          >
            <TTTextField
              v-model="filter.connected_staff_search"
              large
              label="Поиск по HR’у, руководителю, наставнику"
              placeholder="Поиск по ФИО"
              clearable
              append-icon="fal fa-search"
              data-test="connection-input-search"
              @input="debouncedGetData"
            />
          </VCol>
        </VRow>
      </VCol>
      <VCol class="flex-grow-0 mt-1">
        <VMenu
          offset-y
          :nudge-bottom="8"
          bottom
          max-width="466"
          content-class="v-menu-shadow"
          :close-on-content-click="false"
          left
        >
          <template #activator="{ on }">
            <div class="mt-7">
              <VBadge
                :value="selectedFilters"
                dark
                color="black"
                :content="selectedFilters"
                overlap
              >
                <VBtn
                  fab
                  depressed
                  large
                  color="#F5F6F6"
                  :ripple="false"
                  class="table-menu-button"
                  data-test="period-filters-show-menu"
                  v-on="on"
                >
                  <VIcon
                    size="19"
                    color="black"
                  >
                    fal fa-sliders-h
                  </VIcon>
                </VBtn>
              </VBadge>
            </div>
          </template>
          <VCard class="v-menu-card">
            <VCardText class="pa-6">
              <VRow>
                <VCol
                  class="pt-3 pb-3"
                  cols="6"
                >
                  <p class="tt-text-body-2 mb-0">
                    Период ответов
                  </p>
                </VCol>
                <VCol
                  v-if="updatedSearchPeriod.length"
                  cols="6"
                  class="pt-3 text-right"
                >
                  <span
                    class="tt-dark-blue--text"
                    @click="handlePeriodChange('updated', [])"
                  >
                    Сбросить
                  </span>
                </VCol>
                <VCol
                  cols="12"
                  class="pt-0 pb-6"
                >
                  <SRangePicker
                    append-icon="fal fa-calendar-alt"
                    placeholder="Выберите период"
                    :value="updatedSearchPeriod"
                    :max-date="dateNow"
                    single-line
                    hide-details="auto"
                    data-test="updated-search-period"
                    @input="handlePeriodChange('updated', $event)"
                  />
                </VCol>
                <VCol
                  class="pt-0 pb-0"
                  cols="6"
                >
                  <p class="tt-text-body-2 mb-0">
                    Период выхода на работу
                  </p>
                </VCol>
                <VCol
                  v-if="startAtSearchPeriod.length"
                  cols="6"
                  class="pt-0 pb-0 text-right"
                >
                  <span
                    class="tt-dark-blue--text"
                    @click="handlePeriodChange('start_at', [])"
                  >
                    Сбросить
                  </span>
                </VCol>
                <VCol
                  cols="12"
                >
                  <SRangePicker
                    append-icon="fal fa-calendar-alt"
                    placeholder="Выберите период"
                    :value="startAtSearchPeriod"
                    :max-date="dateNow"
                    single-line
                    hide-details="auto"
                    data-test="start-at-search-period"
                    @input="handlePeriodChange('start_at', $event)"
                  />
                </VCol>
              </VRow>
            </VCardText>
          </VCard>
        </VMenu>
      </VCol>
    </VRow>
    <VRow v-if="contentType === 'SELECT_MANY'" />

    <VRow v-if="currentLocationId">
      <VCol v-if="dataLoaded && !data.length">
        <h2 class="mb-3">
          Нет данных
        </h2>
        <p class="mb-0">
          Нет данных для отображения по заданным параметрам.
        </p>
      </VCol>
      <VCol v-else>
        <Component
          :is="componentsMap[contentType]"
          :data="data"
          :meta="meta"
        />
      </VCol>
    </VRow>
    <VRow
      v-else
    >
      <VCol cols="12">
        <h2 class="mb-3">
          Локация не выбрана
        </h2>
        <p class="mb-0">
          Выберите локацию для получения данных.
        </p>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import camelCase from 'lodash/camelCase';
import debounce from 'lodash/debounce';
import { pageLocationToken } from '@/services';
import { contentTypeText } from '@/constants';

import SRangePicker from '@/components/ui/SRangePicker.vue';
import SelectOneAnalytics from '@/components/questionAnalytics/SelectOneAnalytics.vue';
import RateAnalytics from '@/components/questionAnalytics/RateAnalytics.vue';
import SelectManyAnalytics from '@/components/questionAnalytics/SelectManyAnalytics.vue';
import EnterStringAnalytics from '@/components/questionAnalytics/EnterStringAnalytics.vue';
import SHelpTooltip from '@/components/ui/SHelpTooltip.vue';
import YesNoAnalytics from '@/components/questionAnalytics/YesNoAnalytics.vue';
import VoteAnalytics from '@/components/questionAnalytics/VoteAnalytics.vue';

export default {
  name: 'QuestionAnalytics',
  components: {
    SRangePicker,
    SelectOneAnalytics,
    RateAnalytics,
    SelectManyAnalytics,
    EnterStringAnalytics,
    SHelpTooltip,
    YesNoAnalytics,
    VoteAnalytics,
  },
  data() {
    return {
      filter: {
        name: '',
        connected_staff_search: '',
        start_at_from: '',
        start_at_till: '',
        updated_from: '',
        updated_till: '',
      },
      dateNow: this.$dayjs()
        .format('YYYY-MM-DD'),
      updatedSearchPeriod: [],
      startAtSearchPeriod: [],
      task: {},
      level: {},
      data: [],
      meta: {},
      contentType: null,

      dataLoaded: false,

      componentsMap: {
        ENTER_STRING: 'EnterStringAnalytics',
        YES_NO: 'YesNoAnalytics',
        SELECT_ONE: 'SelectOneAnalytics',
        SELECT_MANY: 'SelectManyAnalytics',
        VOTE: 'VoteAnalytics',
        RATE: 'RateAnalytics',
      },

      locations: [],
      currentLocationId: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    selectedFilters() {
      let totalFilters = 0;
      if (this.filter.updated_from) totalFilters += 1;
      if (this.filter.start_at_from) totalFilters += 1;
      return totalFilters;
    },
    title() {
      return this.meta?.question || '';
    },
    path() {
      const path = [
        this.level.name,
        this.task.name,
        contentTypeText[this.contentType],
      ].join(', ');
      return path;
    },
    currentLocationName() {
      if (this.currentLocationId && this.locations.length) {
        const locationIdx = this.locations.findIndex((item) => item.id === this.currentLocationId);
        if (locationIdx !== -1) {
          return this.locations[locationIdx].name;
        }
      }
      return '';
    },
  },
  async created() {
    this.debouncedGetData = debounce(this.handleFilter, 500);
    const loc = pageLocationToken.get();
    if (loc) {
      this.currentLocationId = loc;
    }
    try {
      const { data: { data: locations } } = await this.$repositories.location.list({});
      this.locations = locations;

      this.getData();
    } catch (e) {
      console.warn(e);
    }
  },
  mounted() {
    if (!this.currentLocationId) {
      this.$refs.locationDropDown.isMenuActive = true;
    }
  },
  methods: {

    resetTutorSearch() {
      this.filter.tutor_search = '';
      this.debouncedGetData();
    },
    handlePeriodChange(name, period) {
      this[`${camelCase(name)}SearchPeriod`] = period;
      this.filter[`${name}_from`] = period[0] || '';
      this.filter[`${name}_till`] = period[1] || '';
      this.handleFilter();
    },
    handleLocation(item) {
      pageLocationToken.set(item.id);
      this.currentLocationId = item.id;
      this.handleFilter();
    },
    handleFilter() {
      this.getData();
    },

    async getData() {
      if (!this.currentLocationId) return;
      try {
        const includeAnonymous = !(this.selectedFilters || this.filter.connected_staff_search || this.filter.name);
        const res = await this.$repositories.report.getTaskContentReportDetail({
          data: {
            id: this.id,
            filter: {
              ...this.filter,
              include_anonymous_staff: includeAnonymous,
            },
          },
        });
        const {
          data,
          meta,
          level = {},
          task = {},
          content_type: contentType,
        } = res.data;
        this.data = data;
        this.meta = JSON.parse(meta);
        this.contentType = contentType;
        this.level = level;
        this.task = task;
      } catch (e) {
        console.warn(e);
      }
      this.dataLoaded = true;
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
