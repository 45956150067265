<template>
  <VMenu
    v-model="menu"
    nudge-top="40px"
    :open-on-hover="openOnHover"
    content-class="help-tooltip-card"
    attach
    right
    offset-x
    :ripple="false"
    :close-on-content-click="false"
    :max-width="maxWidth"
  >
    <template #activator="{ on, attrs }">
      <VIcon
        size="15"
        v-bind="attrs"
        color="primary"
        data-test="help-tooltip-btn"
        v-on="on"
      >
        fal fa-question-circle
      </VIcon>
    </template>
    <div class="help-tooltip-card__wrapper">
      <slot />
    </div>
  </VMenu>
</template>

<script>
export default {
  name: 'SHelpTooltip',
  props: {
    openOnHover: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: [String, Number],
      default: 360,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>

<style>
.help-tooltip-card.v-menu__content {
  padding: 20px;
  background-color: transparent;
  box-shadow: none;
}
.help-tooltip-card .help-tooltip-card__wrapper {
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  border: 1px solid #EBECED;
  box-sizing: border-box;
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08);
  padding: 16px;
  line-height: 19px;
  z-index: 0;
}

.help-tooltip-card .help-tooltip-card__wrapper:before,
.help-tooltip-card .help-tooltip-card__wrapper:after {
  content: '';
  position: absolute;
  top: 16px;
  border: 10px solid transparent;
  box-sizing: border-box;
}
.help-tooltip-card .help-tooltip-card__wrapper:before {
  left: -20px;
  border-right: 10px solid #EBECED;
}
.help-tooltip-card .help-tooltip-card__wrapper:after {
  left: -19px;
  border-right: 10px solid #FFFF;
}
</style>
