<template>
  <TTDataTable
    hide-default-footer
    :items="filteredData"
    :headers="headers"
    :items-per-page="data.length"
    data-test="vote-table"
    no-data-text="Никто не выбрал этот вариант ответа"
  >
    <template #header.answers>
      <TTSelect
        v-model="answersFilter"
        :items="answersList"
        dense
        class="state-filter mt-0"
        data-test="vote-filter-answers"
      >
        <template #selection="{ item }">
          <div
            class="tt-black--text text--lighten-2 tt-text-body-2"
          >
            {{ item.text }}
          </div>
        </template>
      </TTSelect>
    </template>
    <!-- ФИО сотрудника -->
    <template #item.staff="{item}">
      <div v-if="item.staff">
        <div
          v-if="!item.staff.is_fired"
          class="d-flex align-center cursor--pointer"
          @click="$router.push(`/staff/${item.staff.id}`)"
        >
          <NewSAvatar
            data-test="vote-analytics-staff-avatar"
            :img="item.staff.photo_url"
            :name="`${item.staff.last_name} ${item.staff.first_name}`"
          />
          <div class="ml-2">
            {{ item.staff.last_name }} {{ item.staff.first_name }}
          </div>
        </div>
        <div
          v-else
          class="d-flex align-center"
        >
          <NewSAvatar
            data-test="vote-analytics-staff-avatar"
            :img="item.staff.photo_url"
            :name="`${item.staff.last_name} ${item.staff.first_name}`"
          />
          <div class="ml-2">
            <p class="mb-0">
              {{ item.staff.last_name }} {{ item.staff.first_name }}
            </p>
            <p class="tt-black--text text--lighten-2">
              Уволен
            </p>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex align-center"
      >
        <NewSAvatar
          :img="item.photo_url"
          :name="validateUserFullName(item.staff.first_name, item.staff.last_name)"
          data-test="vote-analytics-staff-avatar"
        />
        <div class="ml-2">
          Аноним
        </div>
      </div>
    </template>
    <template #item.answers="{item}">
      <div
        v-for="value in item.result.vote.values"
        :key="value"
      >
        <p>{{ answers[value].label }}</p>
      </div>
    </template>
    <template #item.date="{item}">
      <div v-if="item.updated">
        <p>{{ formatDate(item.updated) }}</p>
      </div>
    </template>
  </TTDataTable>
</template>

<script>
import { formatDate } from '@/utils';
import { validateUserFullName } from '@/utils/validateUserFullName';

import NewSAvatar from '@/components/ui/NewSAvatar.vue';

export default {
  name: 'VoteAnalytics',
  components: {
    NewSAvatar,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      answersFilter: -1,
      headers: [
        {
          text: 'Сотрудник',
          value: 'staff',
          sortable: false,
          width: 330,
        },
        {
          text: 'Ответы',
          value: 'answers',
          sortable: false,
        },
        {
          text: 'Дата',
          value: 'date',
          sortable: false,
          width: 180,
        },
      ],
    };
  },
  computed: {
    answers() {
      return this.meta.answers || [];
    },
    answersList() {
      const answersList = [];
      answersList.push({ text: `Все (${this.data.length})`, value: -1 });
      if (this.answers.length) {
        const answersTextMap = this.answers.map((item) => ({
          label: item.label,
          count: 0,
        })) || [];
        this.data.forEach((item) => {
          item.result.vote.values.forEach((value) => {
            answersTextMap[value].count += 1;
          });
        });
        answersTextMap.forEach((item, index) => {
          answersList.push({
            text: `${item.label} (${item.count})`,
            value: index,
          });
        });
      }
      return answersList;
    },
    filteredData() {
      if (this.answersFilter === -1) {
        return this.data;
      }
      return this.data.filter((item) => item.result.vote.values.indexOf(this.answersFilter) !== -1);
    },
  },
  methods: {
    formatDate,
    validateUserFullName,
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
