<template>
  <TTDataTable
    hide-default-footer
    :items="data"
    :headers="headers"
    :items-per-page="data.length"
    data-test="enter-string-table"
  >
    <template #item.staff="{item}">
      <div v-if="item.staff">
        <div
          v-if="!item.staff.is_fired"
          class="d-flex align-center cursor--pointer"
          @click="$router.push(`/staff/${item.staff.id}`)"
        >
          <NewSAvatar
            data-test="enter-string-analytics-staff-avatar"
            :img="item.staff.photo_url"

            :name="`${item.staff.last_name} ${item.staff.first_name}`"
          />
          <div class="ml-2">
            {{ item.staff.last_name }} {{ item.staff.first_name }}
          </div>
        </div>
        <div
          v-else
          class="d-flex align-center"
        >
          <NewSAvatar
            data-test="enter-string-analytics-staff-avatar"
            :img="item.staff.photo_url"

            :name="`${item.staff.last_name} ${item.staff.first_name}`"
          />
          <div class="ml-2">
            <p class="mb-0">
              {{ item.staff.last_name }} {{ item.staff.first_name }}
            </p>
            <p class="tt-black--text text--lighten-2 mb-0">
              Уволен
            </p>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex align-center"
      >
        <NewSAvatar

          :img="item.photo_url"
          :name="validateUserFullName(item.staff.first_name, item.staff.last_name)"
          data-test="enter-string-analytics-staff-avatar"
        />
        <div class="ml-2">
          Аноним
        </div>
      </div>
    </template>
    <template #item.answers="{item}">
      <SExpandable :max-lines="2">
        <p
          v-if="item.result.enter_string"
          class="mb-0"
        >
          {{ item.result.enter_string.value }}
        </p>
      </SExpandable>
    </template>
    <template #item.date="{item}">
      <div v-if="item.updated">
        <p class="mb-0">
          {{ formatDate(item.updated) }}
        </p>
      </div>
    </template>
  </TTDataTable>
</template>

<script>
import { formatDate } from '@/utils';
import { validateUserFullName } from '@/utils/validateUserFullName';

import NewSAvatar from '@/components/ui/NewSAvatar.vue';
import SExpandable from '@/components/ui/SExpandable.vue';

export default {
  name: 'EnterStringAnalytics',
  components: {
    NewSAvatar,
    SExpandable,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Сотрудник',
          value: 'staff',
          sortable: false,
          width: 330,
        },
        {
          text: 'Ответы',
          value: 'answers',
          sortable: false,
        },
        {
          text: 'Дата',
          value: 'date',
          sortable: false,
          width: 180,
        },
      ],
    };
  },
  methods: {
    formatDate,
    validateUserFullName,
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
