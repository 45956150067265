var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTDataTable',{attrs:{"hide-default-footer":"","items":_vm.filteredData,"headers":_vm.headers,"items-per-page":_vm.data.length,"data-test":"yes-no-table","no-data-text":"Никто не выбрал этот вариант ответа"},scopedSlots:_vm._u([{key:"header.answers",fn:function(){return [_c('TTSelect',{staticClass:"state-filter mt-0",attrs:{"items":_vm.answersList,"dense":"","data-test":"yes-no-filter-answers"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tt-black--text text--lighten-2 tt-text-body-2"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.answersFilter),callback:function ($$v) {_vm.answersFilter=$$v},expression:"answersFilter"}})]},proxy:true},{key:"item.staff",fn:function(ref){
var item = ref.item;
return [(item.staff)?_c('div',[(!item.staff.is_fired)?_c('div',{staticClass:"d-flex align-center cursor--pointer",on:{"click":function($event){return _vm.$router.push(("/staff/" + (item.staff.id)))}}},[_c('NewSAvatar',{attrs:{"data-test":"yes-no-analytics-staff-avatar","img":item.staff.photo_url,"name":((item.staff.last_name) + " " + (item.staff.first_name))}}),_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(item.staff.last_name)+" "+_vm._s(item.staff.first_name)+" ")])],1):_c('div',{staticClass:"d-flex align-center"},[_c('NewSAvatar',{attrs:{"data-test":"yes-no-analytics-staff-avatar","img":item.staff.photo_url,"name":((item.staff.last_name) + " " + (item.staff.first_name))}}),_c('div',{staticClass:"ml-2"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.staff.last_name)+" "+_vm._s(item.staff.first_name)+" ")]),_c('p',{staticClass:"tt-black--text text--lighten-2"},[_vm._v(" Уволен ")])])],1)]):_c('div',{staticClass:"d-flex align-center"},[_c('NewSAvatar',{attrs:{"img":item.photo_url,"name":_vm.validateUserFullName(item.staff.first_name, item.staff.last_name),"data-test":"yes-no-analytics-staff-avatar"}}),_c('div',{staticClass:"ml-2"},[_vm._v(" Аноним ")])],1)]}},{key:"item.answers",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.result.yes_no.value ? 'Да' : 'Нет'))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.updated)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.formatDate(item.updated)))])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }