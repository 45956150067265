<template>
  <TTDataTable
    hide-default-footer
    :items="filteredData"
    :headers="headers"
    :items-per-page="data.length"
    data-test="rate-table"
    no-data-text="Никто не выбрал этот вариант ответа"
  >
    <!-- Ответы в шапке таблицы -->

    <template #header.answers>
      <TTSelect
        v-if="meta.type !== 'smile'"
        v-model="answersFilter"
        :items="answersList"
        dense
        class="state-filter mt-0"
        data-test="rate-filter-answers"
      >
        <template #selection="{ item }">
          <div
            class="tt-black--text text--lighten-2 tt-text-body-2"
          >
            {{ item.text }}
          </div>
        </template>
      </TTSelect>
      <TTSelect
        v-else
        v-model="answersFilter"
        :items="answersList"
        dense
        class="state-filter mt-0"
        data-test="rate-filter-answers"
        @change="refreshCommentCell"
      >
        <!-- Кастомное отображение смайликов в селекте -->
        <template #selection="{ item }">
          <div
            v-if="item.value === -1"
            class="tt-black--text text--lighten-2 tt-text-body-2"
          >
            {{ item.text }}
          </div>
          <div
            v-else
            class="d-flex align-baseline"
          >
            <VIcon
              :color="smile(item.value).color"
              size="14"
              class="pr-1"
            >
              {{ smile(item.value).activeIcon }}
            </VIcon>
            <div class="tt-black--text text--lighten-2 tt-text-body-2">
              {{ item.text }}
            </div>
          </div>
        </template>
        <!-- Кастомный список для смайликов -->
        <template #item="{ item, attrs, on }">
          <VListItem
            v-bind="attrs"
            v-on="on"
          >
            <VListItemContent v-if="item.value === -1">
              {{ item.text }}
            </VListItemContent>
            <VListItemContent v-else>
              <div class="d-flex align-baseline">
                <VIcon
                  :color="smile(item.value).color"
                  size="14"
                  class="pr-1"
                >
                  {{ smile(item.value).activeIcon }}
                </VIcon>
                <div>{{ item.text }}</div>
              </div>
            </VListItemContent>
          </VListItem>
        </template>
      </TTSelect>
    </template>

    <!-- Комментарии в шапке таблицы -->
    <template #header.comments>
      <TTSelect
        v-model="commentsFilter"
        :items="commentsList"
        dense
        class="state-filter mt-0"
        data-test="rate-filter-comments"
        @change="refreshCommentCell"
      >
        <template #selection="{ item }">
          <div
            class="tt-black--text text--lighten-2 tt-text-body-2"
          >
            {{ item.text }}
          </div>
        </template>
      </TTSelect>
    </template>

    <!-- ФИО сотрудника -->
    <template #item.staff="{item}">
      <div v-if="item.staff">
        <div
          v-if="!item.staff.is_fired"
          class="d-flex align-start cursor--pointer"
          @click="$router.push(`/staff/${item.staff.id}`)"
        >
          <NewSAvatar
            data-test="rate-analytics-staff-avatar"
            :img="item.staff.photo_url"

            :name="`${item.staff.last_name} ${item.staff.first_name}`"
          />
          <div class="ml-2">
            {{ item.staff.last_name }} {{ item.staff.first_name }}
          </div>
        </div>
        <div
          v-else
          class="d-flex align-start"
        >
          <NewSAvatar
            data-test="rate-analytics-staff-avatar"
            :img="item.staff.photo_url"

            :name="`${item.staff.last_name} ${item.staff.first_name}`"
          />
          <div class="ml-2">
            <p class="mb-0">
              {{ item.staff.last_name }} {{ item.staff.first_name }}
            </p>
            <p class="tt-text-body-2 tt-light-mono-46--text mb-0">
              Уволен
            </p>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex align-center"
      >
        <NewSAvatar

          :img="item.photo_url"
          :name="validateUserFullName(item.first_name, item.last_name)"
          data-test="rate-analytics-staff-avatar"
        />
        <div class="ml-2">
          Аноним
        </div>
      </div>
    </template>

    <!-- Ответы -->
    <template #item.answers="{item}">
      <div v-if="meta.type === 'smile'">
        <VIcon
          :color="smile(item.result.rate.value).color"
          size="14"
          class="pr-1"
        >
          {{ smile(item.result.rate.value).activeIcon }}
        </VIcon>
      </div>
      <div
        v-else
        class="d-flex"
      >
        {{ item.result.rate.value }}
      </div>
    </template>

    <!-- Комментарий -->
    <template #item.comments="{item}">
      <SExpandable
        :key="commentCellKey"
        :max-lines="2"
      >
        {{ item.result.rate.comment ? item.result.rate.comment : '–' }}
      </SExpandable>
    </template>

    <!-- Дата заполнения -->
    <template #item.date="{item}">
      <div v-if="item.updated">
        {{ formatDate(item.updated) }}
      </div>
    </template>
  </TTDataTable>
</template>

<script>
import { formatDate } from '@/utils';
import { smiles } from '@/constants';
import { validateUserFullName } from '@/utils/validateUserFullName';

import NewSAvatar from '@/components/ui/NewSAvatar.vue';
import SExpandable from '@/components/ui/SExpandable.vue';

export default {
  name: 'RateAnalytics',
  components: {
    NewSAvatar,
    SExpandable,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      smiles,
      DEFAULT_SMILE: 0,
      answersFilter: -1,
      commentsFilter: -1,
      headers: [
        {
          text: 'Сотрудник',
          value: 'staff',
          sortable: false,
          width: 250,
        },
        {
          text: 'Ответы',
          value: 'answers',
          sortable: false,
          width: 150,
        },
        {
          text: 'Комментарии',
          value: 'comments',
          sortable: false,
        },
        {
          text: 'Дата',
          value: 'date',
          sortable: false,
          width: 110,
        },
      ],
      commentCellKey: 0,
    };
  },
  computed: {
    actionText() {
      return this.meta.action.text || [];
    },
    answersList() {
      const answersList = [];
      answersList.push({ text: `Все (${this.data.length})`, value: -1 });
      if (this.actionText.length) {
        const actionTextMap = this.actionText.map((item) => ({
          label: item,
          count: 0,
        })) || [];
        this.data.forEach((item) => {
          // Есть бага с бэка, что на опрос RATE:5 может приходить ответ 6!
          if (actionTextMap[item.result.rate.value - 1]) {
            actionTextMap[item.result.rate.value - 1].count += 1;
          }
        });
        actionTextMap.forEach((item, index) => {
          if (this.meta.type === 'smile') {
            answersList.push({
              text: `(${item.count})`,
              value: index + 1,
            });
          } else {
            answersList.push({
              text: `${index + 1} (${item.count})`,
              value: index + 1,
            });
          }
        });
      }
      return answersList;
    },

    commentsList() {
      const commentsList = [];
      commentsList.push({ text: `Все ответы (${this.data.length})`, value: -1 });
      let withComment = 0;
      let withoutComment = 0;
      this.data.forEach((item) => {
        if (item.result.rate.comment) {
          withComment += 1;
        } else {
          withoutComment += 1;
        }
      });
      commentsList.push({ text: `С комментариями (${withComment})`, value: 0 });
      commentsList.push({ text: `Без комментариев (${withoutComment})`, value: 1 });

      return commentsList;
    },

    filteredData() {
      if (this.answersFilter === -1 && this.commentsFilter === -1) {
        return this.data;
      }
      return this.data.filter((item) => {
        let res = true;
        if (this.answersFilter !== -1) {
          res = res && item.result.rate.value === this.answersFilter;
        }
        if (this.commentsFilter !== -1) {
          if (this.commentsFilter) {
            res = res && !item.result.rate.comment;
          } else {
            res = res && item.result.rate.comment;
          }
        }
        return res;
      });
    },
  },
  methods: {
    validateUserFullName,
    formatDate,
    smile(value) {
      return this.smiles[value - 1] || this.smiles[this.DEFAULT_SMILE];
    },
    refreshCommentCell() {
      this.commentCellKey += 1;
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
