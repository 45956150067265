var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTDataTable',{attrs:{"hide-default-footer":"","items":_vm.filteredData,"headers":_vm.headers,"items-per-page":_vm.data.length,"data-test":"rate-table","no-data-text":"Никто не выбрал этот вариант ответа"},scopedSlots:_vm._u([{key:"header.answers",fn:function(){return [(_vm.meta.type !== 'smile')?_c('TTSelect',{staticClass:"state-filter mt-0",attrs:{"items":_vm.answersList,"dense":"","data-test":"rate-filter-answers"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tt-black--text text--lighten-2 tt-text-body-2"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}],null,false,4256175185),model:{value:(_vm.answersFilter),callback:function ($$v) {_vm.answersFilter=$$v},expression:"answersFilter"}}):_c('TTSelect',{staticClass:"state-filter mt-0",attrs:{"items":_vm.answersList,"dense":"","data-test":"rate-filter-answers"},on:{"change":_vm.refreshCommentCell},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.value === -1)?_c('div',{staticClass:"tt-black--text text--lighten-2 tt-text-body-2"},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('div',{staticClass:"d-flex align-baseline"},[_c('VIcon',{staticClass:"pr-1",attrs:{"color":_vm.smile(item.value).color,"size":"14"}},[_vm._v(" "+_vm._s(_vm.smile(item.value).activeIcon)+" ")]),_c('div',{staticClass:"tt-black--text text--lighten-2 tt-text-body-2"},[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('VListItem',_vm._g(_vm._b({},'VListItem',attrs,false),on),[(item.value === -1)?_c('VListItemContent',[_vm._v(" "+_vm._s(item.text)+" ")]):_c('VListItemContent',[_c('div',{staticClass:"d-flex align-baseline"},[_c('VIcon',{staticClass:"pr-1",attrs:{"color":_vm.smile(item.value).color,"size":"14"}},[_vm._v(" "+_vm._s(_vm.smile(item.value).activeIcon)+" ")]),_c('div',[_vm._v(_vm._s(item.text))])],1)])],1)]}}]),model:{value:(_vm.answersFilter),callback:function ($$v) {_vm.answersFilter=$$v},expression:"answersFilter"}})]},proxy:true},{key:"header.comments",fn:function(){return [_c('TTSelect',{staticClass:"state-filter mt-0",attrs:{"items":_vm.commentsList,"dense":"","data-test":"rate-filter-comments"},on:{"change":_vm.refreshCommentCell},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tt-black--text text--lighten-2 tt-text-body-2"},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.commentsFilter),callback:function ($$v) {_vm.commentsFilter=$$v},expression:"commentsFilter"}})]},proxy:true},{key:"item.staff",fn:function(ref){
var item = ref.item;
return [(item.staff)?_c('div',[(!item.staff.is_fired)?_c('div',{staticClass:"d-flex align-start cursor--pointer",on:{"click":function($event){return _vm.$router.push(("/staff/" + (item.staff.id)))}}},[_c('NewSAvatar',{attrs:{"data-test":"rate-analytics-staff-avatar","img":item.staff.photo_url,"name":((item.staff.last_name) + " " + (item.staff.first_name))}}),_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(item.staff.last_name)+" "+_vm._s(item.staff.first_name)+" ")])],1):_c('div',{staticClass:"d-flex align-start"},[_c('NewSAvatar',{attrs:{"data-test":"rate-analytics-staff-avatar","img":item.staff.photo_url,"name":((item.staff.last_name) + " " + (item.staff.first_name))}}),_c('div',{staticClass:"ml-2"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.staff.last_name)+" "+_vm._s(item.staff.first_name)+" ")]),_c('p',{staticClass:"tt-text-body-2 tt-light-mono-46--text mb-0"},[_vm._v(" Уволен ")])])],1)]):_c('div',{staticClass:"d-flex align-center"},[_c('NewSAvatar',{attrs:{"img":item.photo_url,"name":_vm.validateUserFullName(item.first_name, item.last_name),"data-test":"rate-analytics-staff-avatar"}}),_c('div',{staticClass:"ml-2"},[_vm._v(" Аноним ")])],1)]}},{key:"item.answers",fn:function(ref){
var item = ref.item;
return [(_vm.meta.type === 'smile')?_c('div',[_c('VIcon',{staticClass:"pr-1",attrs:{"color":_vm.smile(item.result.rate.value).color,"size":"14"}},[_vm._v(" "+_vm._s(_vm.smile(item.result.rate.value).activeIcon)+" ")])],1):_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(item.result.rate.value)+" ")])]}},{key:"item.comments",fn:function(ref){
var item = ref.item;
return [_c('SExpandable',{key:_vm.commentCellKey,attrs:{"max-lines":2}},[_vm._v(" "+_vm._s(item.result.rate.comment ? item.result.rate.comment : '–')+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.updated)?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.updated))+" ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }